<template>
    <!-- Page: pages/my-profile -->

    <br/>

    <breadcrumbs :items="path"/>

    <br>

    <div class="container">
        <h1 class="profile-title" style="float:left;">My Profile</h1>
        <user-tools/>
    </div>
    <br>
    <hr>
    <br>

    <div class="general-container">
        <div class="general-container__content" v-if="user">

            <div v-if="dispayDeniedMessage" class="error-message">
                ACCESS DENIED: Active Subscription required to access the product.
            </div>

            <div v-if="user && user.subscriptionError" class="error-message">{{ user.subscriptionError }}</div>

            <div v-if="user.subscription && user.canManageSubscription">
                <h3>Subscription details</h3>

                Status:
                <span v-if="user.subscription.status === 'in_trial'">
                    Trial
                    <span v-if="user.subscription.trialEndDate">
                        ({{ reformatDateString(user.subscription.trialEndDate) }})
                    </span>
                </span>

                <span v-else>Active</span>

                <span v-if="user.canManageSubscription && user.company">
                    <br>Company Users Limit: {{ user.subscription.usersCount }}
                </span>

                <span v-if="user.subscription.nextBillingDate">
                    <br>Next Billing Date: {{ reformatDateString(user.subscription.nextBillingDate) }}
                    (${{ user.subscription.nextBillingAmount }})
                </span>
            </div>

            <div v-else-if="!user.subscription">
                <h3>Subscription details</h3>
                Status: No subscription
                <button class="see-details button" @click="openModal()">
                    See Subscription Options
                </button>
            </div>

<!--
            <button class="button" @click="openCheckout"
                    v-if="cbLoaded && !user.subscription && user.canManageSubscription"
            >
                Subscribe
            </button>
-->
            <button class="button" @click="openPortal" v-if="cbLoaded && user.subscription && user.canManageSubscription">
                Manage Subscription Account
            </button>

            <preloader v-if="!cbLoaded"/>

        </div>
    </div>






    <div class="general-container">
        <div class="general-container__content" v-if="user">

            <h3>My Profile</h3>

            <div class="field">
                <label class="label" for="p-photo">Profile Photo</label>
                <div class="control" v-if="editProfile">
                    <input class ="input" type="file" id="p-photo" accept= "image/png, image/jpeg" v-on:change="onFileChange">
                </div>
                 <div class="control" v-else>
                    <img v-if="user.image" :src="user.image.imageUrl" alt="Profile Icon" >
                    <p v-else>{{ '-' }}</p>
                 </div>

            </div>

            <div class="field">
                <label class="label" for="p-login">Username</label>
                <div class="control" v-if="editProfile">
                    <input class="input" type="text" id="p-login"
                           readonly
                           v-model="userParams.login">
                </div>
                <p v-else>{{ user.login || '-' }}</p>
            </div>

            <div class="field">
                <label class="label" for="p-fname">First Name</label>
                <div class="control" v-if="editProfile">
                    <input class="input" type="text" id="p-fname"
                           required
                           v-model="userParams.first_name">
                </div>
                <p v-else>{{ user.first_name || '-' }}</p>
            </div>

            <div class="field">
                <label class="label" for="p-lname">Last Name</label>
                <div class="control" v-if="editProfile">
                    <input class="input" type="text" id="p-lname"
                           required
                           v-model="userParams.last_name">
                </div>
                <p v-else>{{ user.last_name || '-' }}</p>
            </div>

            <div class="field">
                <label class="label" for="p-email">Email</label>
                <div class="control" v-if="editProfile">
                    <input class="input" type="text" id="p-email"
                           required
                           v-model="userParams.email">
                </div>
                <p v-else>{{ user.email || '-' }}</p>
            </div>

            <div class="field">
                <label class="label" for="p-phone">Phone</label>
                <div class="control" v-if="editProfile">
                    <input class="input" type="text" id="p-phone" v-model="userParams.phone">
                </div>
                <p v-else>{{ user.phone || '-' }}</p>
            </div>

            <div class="field" v-if="!user.company">
                <label class="label" for="p-company">Company</label>
                <div class="control" v-if="editProfile">
                    <input class="input" type="text" id="p-company" v-model="userParams.company_name">
                </div>
                <p v-else>{{ user.company_name || '-' }}</p>
            </div>

            <div class="field">
                <label class="label" for="industry">Industry</label>
                <div class="control" v-if="editProfile">
                    <input class="input" type="text" name="industry" id="industry" v-model="userParams.industry">
                </div>
                <p v-else>{{ user.industry || '-' }}</p>
            </div>

            <div v-if="profileError" v-html="profileError" class="error-message"></div>

            <button class="button" v-if="!editProfile" @click.prevent="editProfile = true">Edit Profile</button>

            <button class="button" v-else
                    @click.prevent="saveProfile()"
                    :disabled="pendingEditProfile ? '' : null"
            >
                Save Profile
            </button>

            <button class="button" v-if="editProfile"
                    @click.prevent="editProfile = false; profileError = ''">
                Cancel
            </button>

            <button class="button" v-if="!showChangePassword && !editProfile"
                    @click.prevent="showChangePassword = true">
                Change Password
            </button>

            <div v-else>
                <br>
                <h3>Change Password</h3>

                <div class="field">
                    <label class="label" for="p-old-password">Old Password</label>
                    <div class="control">
                        <input class="input" type="password" id="p-old-password"
                               required
                               v-model="passwordParams.oldPassword"
                        >
                    </div>
                </div>

                <div class="field">
                    <label class="label" for="p-new-password">New Password</label>
                    <div class="control">
                        <input class="input" type="password" id="p-new-password"
                               required
                               v-model="passwordParams.newPassword">
                    </div>
                    <PasswordRequirements :password="passwordParams.newPassword"/>
                </div>

                <div class="field">
                    <label class="label" for="p-confirm-password">Confirm Password</label>
                    <div class="control">
                        <input class="input" type="password" id="p-confirm-password"
                               v-model="passwordParams.confirmPassword">
                    </div>
                </div>

                <div v-if="passwordError" v-html="passwordError" class="error-message"></div>

                <button class="button"
                        @click.prevent="savePassword()"
                        :disabled="pendingChangePassword ? '' : null"
                >
                    Change
                </button>

                <button class="button" @click.prevent="showChangePassword = false; passwordError = ''">Cancel</button>

            </div>
        </div>
    </div>

    <div class="general-container" v-if="user && user.company && user.company.isAdmin">
        <div class="general-container__content">
            <h3>Company</h3>

            <div class="field">
                <label class="label" for="p-cname">Name</label>
                <div class="control" v-if="editCompany">
                    <input class="input" type="text" id="p-cname" required v-model="companyParams.name">
                </div>
                <p v-else>{{ user.company.name || '-' }}</p>
            </div>
            <div class="field">
                <label class="label" for="p-curl">Website URL</label>
                <div class="control" v-if="editCompany">
                    <input class="input" type="text" id="p-curl" v-model="companyParams.url">
                </div>
                <p v-else>{{ user.company.url || '-' }}</p>
            </div>
            <div class="field">
                <label class="label" for="p-caudi">Audience</label>
                <div class="control" v-if="editCompany">
                    <input class="input" type="text" id="p-caudi" v-model="companyParams.audience">
                </div>
                <p v-else>{{ user.company.audience || '-' }}</p>
            </div>

            <div v-if="companyError" v-html="companyError" class="error-message"></div>

            <button class="button" v-if="!editCompany && user.company.isAdmin" @click.prevent="editCompany = true">
                Edit Company Profile
            </button>
            <button class="button" v-else-if="editCompany"
                    @click.prevent="saveCompany()"
                    :disabled="pendingEditCompany ? '' : null"
            >
                Save Company Profile
            </button>
            <button class="button" v-if="editCompany"
                    @click.prevent="editCompany = false; companyError = ''">
                Cancel
            </button>
        </div>
    </div>

    <company-profiles
        v-if="user && user.company && user.company.isAdmin"
        :profiles-limit="user.subscription ? user.subscription.usersCount : 0"
        :user="user"
        @manage-subscription="openPortal()"
        @subscribe="loadCheckout()"
    ></company-profiles>

    <!-- Modal for agreeing to ToS -->
    <div id="tos-modal" :class="(!user.subscription && (!user.agreedTos)) ? 'modal is-active' : 'modal'">
<!--        <div class="modal-background" @click="closeModal()"></div>-->
        <div class="modal-background"></div>
        <div class="modal-card">
            <section class="modal-card-body">
<!--                <p class="modal-card-subtitle">All subscriptions include a free 14-day trial period.</p> -->
                <div class="columns">
                    <div class="column">
                        <div class="card has-text-centered color-2">
                            <div class="card-content">
                                <div class="content">
                                    <p class="title is-3 mb-0">Terms of Service, Saas Agreement, and Privacy Policy </p>
                                    <div class="text-white mt-30">
                                        <div class="field">
                                            <label for="tos">
                                                <input class="checkbox" type="checkbox" id="tos" v-model="tosParams.agreed">
                                                I agree to to <a href="https://app.mygovguide.com/terms-of-use" target="_blank">Terms of Use, SaaS Agreement</a> and the <a href="https://app.mygovguide.com/privacy-policy" target="_blank">Privacy Policy</a>.
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="tosError" class="error-message" v-html="tosError"></div>

                                <footer class="card-footer">
                                    <a href="#" @click="agreeTos" :class="(tosLoading ? 'disabled' : '')  + ' card-footer-item'">Submit</a>
                                </footer>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>

    <!-- Modal for signing up for a trial -->
    <div id="trial-modal" :class="(!user.subscription && (user.subscriptionIsValid != true)) ? 'modal is-active' : 'modal'">
<!--        <div class="modal-background" @click="closeModal()"></div>-->
        <div class="modal-background"></div>
        <div class="modal-card" :class="user && user.company && user.company.isAdmin ? 'company-card' : ''">
            <section class="modal-card-body">
<!--                <p class="modal-card-subtitle">All subscriptions include a free 14-day trial period.</p> -->
                <div>
                    <div>
                        <div class="card has-text-centered color-2">
                            <div class="card-content">
                                <div class="content">
                                    <p class="title is-3 mb-0">Start your 14-day free trial of MyGovGuide</p>
                                    <p class="subtitle is-4 price mt-20 mb-10">$0 today, no credit card required.</p>
                                    <p class="subtitle is-5 desc fs-18">$5,400/year after 14-day free trial.</p>
                                    <ul>
                                        <li>Full Access Account</li>
                                        <li>1 user license</li>
                                        <li>Search</li>
                                        <li>Track</li>
                                        <li>Tag</li>
                                        <li>Record</li>
                                    </ul>
                                    <p class="extra">Enterprise Licenses Available</p>
                                </div>

                                <footer class="card-footer">
                                    <a href="#" @click="openCheckout('annual')" class="card-footer-item">Start Your Free Trial Today</a>
                                </footer>
                            </div>
                        </div>
                    </div>
                    <p class="sub-detail">If you are a state government organization or employee, please email us at <a href="mailto:contact@mygovguide.com">contact@mygovguide.com</a> to request a quote for discounted government pricing.</p>
                </div>
            </section>
        </div>
    </div>

    <!-- Modal for expired subscription / subscription options -->
    <div id="subscription-modal--trial-expired" :class="(user.subscription && (compareDate(user.subscription.trialEndDate) === true) && (user.subscriptionIsValid != true)) ? 'modal is-active' : 'modal'">
<!--        <div class="modal-background" @click="closeModal()"></div>-->
        <div class="modal-background"></div>
        <div class="modal-card" :class="user && user.company && user.company.isAdmin ? 'company-card' : ''">
<!--            <header class="modal-card-head">
                <p class="modal-card-title">MyGovGuide Subscription</p>
                <button class="delete" aria-label="close" @click="closeModal()"></button>
            </header>-->
            <section class="modal-card-body">
<!--                <p class="modal-card-subtitle">All subscriptions include a free 14-day trial period.</p>-->
                <div class="columns">
<!--
                    <div class="column">
                        <div class="card has-text-centered color-1">
                            <div class="card-content">
                                <div class="content">
                                    <p class="title is-3">Professional <br /> Monthly</p>
                                    <p class="subtitle is-4 price">$499</p>
                                    <p class="subtitle is-5 desc">per license/month</p>
                                    <ul>
                                        <li>Full Access Account</li>
                                        <li>1 user license</li>
                                        <li>Search</li>
                                        <li>Track</li>
                                        <li>Tag</li>
                                        <li>Record</li>
                                    </ul>
                                </div>

                                <footer class="card-footer">
                                    <a href="#" @click="openCheckout('monthly')" class="card-footer-item">Subscribe to MyGovGuide Professional Monthly</a>
                                </footer>
                            </div>
                        </div>
                    </div>
-->
                    <div class="column">
                        <div class="card has-text-centered color-2">
                            <div class="card-content">
                                <div class="content">
                                    <p class="title is-3 mb-0">Professional <br /> Subscription</p>
                                    <p class="extra">Annual License</p>
                                    <p class="subtitle is-4 price">$5,400</p>
                                    <p class="subtitle is-5 desc">per license/year</p>
                                    <ul>
                                        <li>Full Access Account</li>
                                        <li>1 user license</li>
                                        <li>Search</li>
                                        <li>Track</li>
                                        <li>Tag</li>
                                        <li>Record</li>
                                    </ul>
                                    <p class="extra">Enterprise Licenses Available</p>
                                </div>

                                <footer class="card-footer">
                                    <a href="#" @click="openCheckout('annual')" class="card-footer-item">Subscribe to MyGovGuide Professional Annually</a>
                                </footer>
                            </div>
                        </div>
                    </div>
<!--
                    <div class="column" v-if="user && user.company && user.company.isAdmin">
                        <div class="card has-text-centered color-1">
                            <div class="card-content">
                                <div class="content">
                                    <p class="title is-3">Enterprise <br /> Monthly</p>
                                    <p class="subtitle is-4 price">$1,999</p>
                                    <p class="subtitle is-5 desc">per account/month</p>
                                    <ul>
                                        <li>Full Access Account</li>
                                        <li>up to 5 user licenses</li>
                                        <li>Search</li>
                                        <li>Track</li>
                                        <li>Tag</li>
                                        <li>Record</li>
                                    </ul>
                                </div>

                                <footer class="card-footer">
                                    <a href="#" @click="openCheckout('enterprise-monthly')" class="card-footer-item">Subscribe to MyGovGuide Enterprise Monthly</a>
                                </footer>
                            </div>
                        </div>
                    </div>
-->
                    <p class="sub-detail">If you are a state government organization or employee, please email us at <a href="mailto:contact@mygovguide.com">contact@mygovguide.com</a> to request a quote for discounted government pricing.</p>

                </div>
            </section>

        </div>
    </div>
    <!-- End Page: pages/my-profile -->
</template>

<script>
    import { computed, onMounted, reactive, ref, watch } from "vue";
    import { useStore } from "vuex";
    import GeneralContainer from "./general-container";
    import UserTools from "./user-tools";
    import SearchBlock from "./search-block";
    import Breadcrumbs from "./breadcrumbs";
    import useUser from "@/helpers/useUser";
    import { ACTION_TYPES, MUTATION_TYPES } from "@/constants";
    import { reformatDateString } from "@/helpers/filter";
    import Preloader from "./preloader";
    import RequestHandler from "@/handler/RequestHandler";
    import { useRouter } from "vue-router";
    import CompanyProfiles from "./company-profiles";
    import PasswordRequirements from "./password-requirements";

    export default {
        components: {
            PasswordRequirements,
            CompanyProfiles,
            Preloader,
            Breadcrumbs,
            SearchBlock,
            UserTools,
            GeneralContainer
        },
        methods: {
            onFileChange(e) {
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                this.createImage(files[0]);
            },

            createImage(file) {
                let image = new Image();
                let reader = new FileReader();
                let vm = this;

                reader.onload = (e) => {
                    vm.image = e.target.result;
                    vm.image = vm.image.split(',')[1]
                    this.userParams.image = vm.image
                };
                reader.readAsDataURL(file);
            },

            removeImage: function (e) {
                this.image = '';
            },

            closeModal: function() {
                const el = document.querySelectorAll('.modal.is-active')
                el.forEach(node => {
                    node.classList.remove('is-active');
                });
            },

            openModal: function() {
                if(this.user.subscription && this.user.subscription.trialEndDate.length && this.user.subscription.trialEndDate > new Date()) {
                    const modalSub = document.getElementById('subscription-modal--trial-expired')
                    modalSub.classList.add('is-active');
                    document.body.classList.add('position-fixed');
                } else {
                    const modal = document.getElementById('trial-modal')
                    modal.classList.add('is-active');
                    document.body.classList.remove('position-fixed');
                }
            }
        },
        mounted() {
            if (this.user.image) {
                let img = {
                    'src': encodeURI(this.user.image.imageUrl),
                    'height': this.user.image.imageHeight,
                    'width': this.user.image.imageWidth
                };
            }
        },
        setup() {
            const router = useRouter();
            const store = useStore();
            const { user } = useUser();
            const showSearch = computed(() => store.getters.showSearch);
            const cbLoaded = ref(false);
            const editProfile = ref(false);
            const editCompany = ref(false);
            const pendingEditProfile = ref(false);
            const pendingEditCompany = ref(false);
            const showChangePassword = ref(false);
            const pendingChangePassword = ref(false);
            const dispayDeniedMessage = ref(false);
            const cbPlanOption = ref('');
            const passwordError = ref('');
            const profileError = ref('');
            const companyError = ref('');
            const passwordParams = reactive({
                oldPassword: null,
                newPassword: null,
                confirmPassword: null,
            });

            const tosParams = reactive({
                agreed: false,
            });
            const tosLoading = ref(false);
            const tosError = ref(null);

            const userParams = reactive({
                'first_name': '',
                'last_name': '',
                'email': '',
                'phone': '',
                'company_name': '',
                'industry': '',
                'image': '',
                'login': '',
            });

            const companyParams = reactive({
                'name': '',
                'url': '',
                'audience': '',
            });

            const setUserParamsFromUser = () => {
                if (user.value) {
                    userParams.first_name = user.value.first_name;
                    userParams.last_name = user.value.last_name;
                    userParams.email = user.value.email;
                    userParams.phone = user.value.phone;
                    userParams.company_name = user.value.company_name;
                    userParams.industry = user.value.industry;
                    userParams.login = user.value.login;
                    if (user.value.company) {
                        userParams.company_name = user.value.company.name;
                        companyParams.name = user.value.company.name;
                        companyParams.url = user.value.company.url;
                        companyParams.audience = user.value.company.audience;
                    }
                }
            };

            if (user.value) {
                setUserParamsFromUser();
            }
            watch(user, () => setUserParamsFromUser());

            let cbInstance = null;
            const reloadUser = () => {
                store.dispatch(ACTION_TYPES.loadUser);
                setTimeout(() => {
                    store.dispatch('fetchAgencies');
                    store.dispatch('loadAllDefinitions');
                }, 2000);
            };

            const saveProfile = () => {
                RequestHandler.editUser(userParams, store.getters.csrf)
                    .then(() => {
                        reloadUser();
                        editProfile.value = false;
                        profileError.value = '';
                    })
                    .catch((error) => {
                        profileError.value = (error.response.data && error.response.data.error)
                            ? error.response.data.error.replaceAll('ERROR:', '<br>●')
                            : 'Something went wrong, please try again';
                    })
            };

            const agreeTos = () => {
                if (tosParams.agreed !== true) {
                    tosError.value = 'You must agree to Terms of Use, SaaS Agreement and the Privacy Policy';
                    return;
                }

                tosLoading.value = true;

                RequestHandler
                    .agreeTos({
                        agreedTos: tosParams.agreed ? Math.floor(new Date().getTime() / 1000) : '',
                    }, store.getters.csrf)
                    .then(() => {
                        tosError.value = '';
                        tosLoading.value = false;
                        reloadUser();
                    })
                    .catch(error => {
                        tosLoading.value = false;
                        tosError.value = (error.response.data && error.response.data.error)
                            ? error.response.data.error.replaceAll('ERROR:', '<br>●')
                            : 'Something went wrong, please try again';
                    })
                ;
            };

            const saveCompany = () => {
                if (!companyParams.name) {
                    companyError.value = 'Company name is required';
                    return;
                }
                RequestHandler.editCompany(companyParams, store.getters.csrf)
                    .then(() => {
                        reloadUser();
                        editCompany.value = false;
                        companyError.value = '';
                    })
                    .catch((error) => {
                        companyError.value = (error.response.data && error.response.data.error)
                            ? error.response.data.error.replaceAll('ERROR:', '<br>●')
                            : 'Something went wrong, please try again';
                    })
            };

            const savePassword = () => {
                RequestHandler.updatePassword(passwordParams, store.getters.csrf)
                    .then(() => {
                        showChangePassword.value = false;
                        passwordParams.oldPassword = null;
                        passwordParams.newPassword = null;
                        passwordParams.confirmPassword = null;
                        passwordError.value = '';
                    })
                    .catch((error) => {
                        passwordError.value = (error.response.data && error.response.data.error)
                            ? error.response.data.error.replaceAll('ERROR:', '<br>●')
                            : 'Something went wrong, please try again';
                    });
            };

            const openCheckout = (planOption) => {
                cbPlanOption.value = planOption;
                loadCheckout();
            };

            // debug function
            const printUser = () => {
                console.log(JSON.stringify(user));
            };

            const compareDate = (endDate) => {
                if (endDate == null) return true;
                const today = new Date();
                const yyyy = today.getFullYear();
                let mm = today.getMonth() + 1; // Months start at 0!
                let dd = today.getDate();

                // add a zero to the front if the day of the month is less than 10
                if (dd < 10) dd = '0' + dd;
                if (mm < 10) mm = '0' + mm;

                const formattedToday = yyyy + '' + mm + '' + dd;
                const formattedEndDate = endDate.replace(/-/g, '');

                if (formattedEndDate > formattedToday) {
                    return true; // trial expired
                } else {
                    return false; // trial valid
                }
            }

            const loadCheckout = () => {
                dispayDeniedMessage.value = false;
                cbInstance.openCheckout({
                    hostedPage: () => RequestHandler.loadChargebeeCheckout(cbPlanOption.value),
                    success: reloadUser,
                    close: reloadUser,
                });
            }

            const openPortal = () => {
                dispayDeniedMessage.value = false;
                const cbPortal = cbInstance.createChargebeePortal();
                cbInstance.setPortalSession(() => RequestHandler.loadChargebeePortalSession());
                cbPortal.open({
                    close: reloadUser,
                    subscriptionChanged: reloadUser,
                    subscriptionCancelled: reloadUser,
                    subscriptionPaused: reloadUser,
                    subscriptionResumed: reloadUser,
                    subscriptionReactivated: reloadUser
                });
            };

            const initChargebee = () => {
                if (typeof Chargebee === undefined) {
                    setTimeout(() => initChargebee(), 1000);
                    return;
                }
                RequestHandler.loadChargebeeConfig().then(({ site }) => {
                    Chargebee.init({ site });
                    cbInstance = Chargebee.getInstance();
                    cbLoaded.value = true;
                });
            };

            onMounted(() => {
                initChargebee();
                store.commit(MUTATION_TYPES.setNewlyRegistered, false);
                if (router.currentRoute.value.query.denied === 'Y') {
                    dispayDeniedMessage.value = true;
                }
            });

            return {
                path: [{ title: 'My Profile' }],
                showSearch,
                user,
                openCheckout,
                loadCheckout,
                openPortal,
                cbLoaded,
                passwordParams,
                dispayDeniedMessage,
                showChangePassword,
                savePassword,
                cbPlanOption,
                passwordError,
                pendingEditProfile,
                pendingChangePassword,
                editProfile,
                saveProfile,
                profileError,
                userParams,
                saveCompany,
                editCompany,
                pendingEditCompany,
                companyParams,
                companyError,
                reformatDateString,
                printUser,
                compareDate,
                tosParams,
                tosError,
                agreeTos,
                tosLoading,
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .modal {
        z-index: 2147483647; // maximum possible z-index to hopefully beat out Useitful tours
    }

    .modal-card-subtitle {
        text-align: center;
    }

    .bookmarks-filter span {
        opacity: 0.7;

        &.active {
            opacity: 1;
        }
    }

    .disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    .button {
        &:hover, &:focus, &:active {
            color: $white;
        }
        &.see-details {
            display: block;
            margin: 10px 0;
        }
    }

    .general-container {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .error-message {
        margin-bottom: 2em;
        font-style: italic;
        color: $red;
    }

    .password-details {
        font-style: italic;
        font-size: 12px;
        color: #5a6171;
        padding-top: 4px;
    }

    .text-white {
        color: $white;
    }

    #tos-modal a {
        color: $white;
    }

    #subscription-modal--trial-expired, #trial-modal, #tos-modal {
        .modal-card-title {
            margin-bottom: 0px;
        }

        .card {
            .title, .subtitle {
                color: $white;
                &.price {
                    margin-bottom: none;
                }
                &.desc {
                    border-bottom: 1px solid #fff;
                    padding-bottom: 20px;
                }
            }
            .title {
                line-height: 1em;
            }
            .content {
                ul {
                    list-style-type: none !important;
                    margin-left: 0px;
                }
            }
            .card-footer {
                border-top: none;
                .card-footer-item {
                    text-decoration: none;
                    border-radius: 6px;
                    &:hover, &:focus {
                        text-decoration: underline;
                    }
                }
            }
            &.color-1 {
                background-color: $blue--med;
                color: $white;
                .card-footer .card-footer-item {
                    background-color: $blue--dark;
                    color: $white;
                }
            }
            &.color-2 {
                background-color: $blue--dark;
                color: $white;
                .card-footer .card-footer-item {
                    background-color: $blue--med;
                    color: $white;
                }
            }
        }

        .company-card {
            width: 960px;
            @media screen and (max-width: 1021px) {
                width: 100%;
            }
        }

        .sub-detail {
            margin: 10px 15px;
        }
    }

    .extra {
        font-size: 18px;
        color: #fff;
        font-weight: 600;
    }
    .mb-0 {
        margin-bottom: 0px;
    }
    .mb-10 {
        margin-bottom: 10px;
    }
    .mt-20 {
        margin-top: 20px;
    }
    .fs-18 {
        font-size: 18px;
    }
</style>

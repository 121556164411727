<template>
    <!-- Component: organisms/company-profiles -->
    <div class="general-container">
        <div class="general-container__content">
            <h3>Company Users</h3>

            <div v-if="!loadingUsers">
                <ul v-if="!loadingUsers && users.length">
                    <li v-for="companyUser in users">
                        {{ companyUser.firstName }} {{ companyUser.lastName }} ({{ companyUser.email }})
                    </li>
                </ul>
                <button class="button" @click.prevent="showRegistrationForm()" v-if="!registrationFormVisible">
                    Register New User
                </button>

                <div class="error-message" v-if="notEnoughSlotsErrorVisible">
                    You're limited to {{ profilesLimit }} user{{ profilesLimit !== 1 ? 's' : '' }}.<br>
                    Please contact us at <a href="mailto:contact@mygovguide.com">contact@mygovguide.com</a> if you are interested in adding additional company users.
                </div>
                <div v-if="notEnoughSlotsErrorVisible && user">
                    <button class="button" @click.prevent="$emit('manage-subscription')" v-if="user.subscription">
                        Edit Subscription
                    </button>
                    <button class="button" @click.prevent="$emit('subscribe')" v-else>
                        Subscribe
                    </button>
                </div>
            </div>
            <preloader v-else/>

            <div v-if="registrationFormVisible">
                <hr>
                <h4>New user</h4>
                <registration-form :for-company="true" @registered="registrationCompleted()"></registration-form>
            </div>
        </div>
    </div>
    <!-- End Component: organisms/company-profiles -->
</template>

<script>
    import TagInput from "./tag-input";
    import Preloader from "./preloader";
    import { onMounted, ref, watch } from "vue";
    import RequestHandler from "@/handler/RequestHandler";
    import RegistrationForm from "./registration-form";

    export default {
        name: 'company-profiles',
        components: { RegistrationForm, Preloader, TagInput },
        props: ['profilesLimit', 'user'],
        emits: ['manage-subscription'],
        setup(props) {
            const users = ref([]);
            const loadingUsers = ref(false);
            const registrationFormVisible = ref(false);
            const notEnoughSlotsErrorVisible = ref(false);

            const loadUsers = () => {
                loadingUsers.value = true;
                RequestHandler.loadCompanyUsers().then((data) => {
                    users.value = data.users;
                    loadingUsers.value = false;
                });
            }

            const showRegistrationForm = () => {
                if (users.value.length >= props.profilesLimit) {
                    notEnoughSlotsErrorVisible.value = true;
                } else {
                    notEnoughSlotsErrorVisible.value = false;
                    registrationFormVisible.value = true;
                }
            };

            const registrationCompleted = () => {
                loadUsers();
                registrationFormVisible.value = false;
            };

            const updateLimitationLayout = (a) => {
                if (notEnoughSlotsErrorVisible.value && props.profilesLimit > users.value.length) {
                    notEnoughSlotsErrorVisible.value = false;
                    registrationFormVisible.value = true;
                }
                if (registrationFormVisible.value && props.profilesLimit <= users.value.length) {
                    registrationFormVisible.value = false;
                    notEnoughSlotsErrorVisible.value = true;
                }
            };

            watch(() => props.profilesLimit, updateLimitationLayout);
            watch(users, updateLimitationLayout);

            onMounted(() => {
                loadUsers();
            });

            return {
                users,
                loadUsers,
                loadingUsers,
                registrationCompleted,
                registrationFormVisible,
                notEnoughSlotsErrorVisible,
                showRegistrationForm,
            };
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .error-message {
        margin-bottom: 1em;
        margin-top: 2em;
        font-style: italic;
        color: $red;
    }
</style>
